$childrenWidth: 618px;
$childrenHeight: 240px;
$ofWidth: 83px;
$ofHeight: 109px;
$valeWidth: 322px;
$valeHeight: 242px;
$authorWidth: 661px;
$sublineWidth: 608px;

#splash {
  .hero-slide.parallax {
    position: relative;
  }
  min-height: 740px;

  .novel_title {
    display: inline-block;
  }

  .subline {
    display: block;
  }

  #novel-title-wrap {
    text-align: center;


    img:nth-child(1) {
      margin: 0 20px;
    }

    img:nth-child(2) {
      margin: 0 20px;
      position: relative;
      top: 30px;
    }

    img:nth-child(3) {
      margin: 0 5px;
    }
  }

  #novel-subline {
    text-align: center;
    .subline {
      margin: 30px auto;
    }
  }

  #novel-author {
    width: $authorWidth;
    margin: 0 auto;
  }

}

@media only screen and (max-width: 1048px) {
  #splash .hero-slide {
    background-attachment: scroll;
    background-size: auto;
  }  
}


@media (max-width: 1245px) {
  $titleResize: .65;

  #splash #novel-title-wrap {
    margin: 0 auto;
    
    img:nth-child(1) {
      margin: 0 10px 0 0;
      width: $childrenWidth * $titleResize;
    }
    img:nth-child(2) {
      margin: 0 10px;
      top: 20px;
      width: $ofWidth * $titleResize;
    }
    img:nth-child(3) {
      margin: 0 5px 0 0;
      width: $valeWidth * $titleResize;
    }
  }

  #splash #novel-subline {
    width: $sublineWidth * $titleResize;
    margin: 0 auto;
  }

  #splash #novel-author {
    width: $authorWidth * $titleResize;
    margin: 0 auto;
  }
}

@media (max-width: 800px) {
  $titleResize: .5;

  #splash #novel-title-wrap {
    margin: 0 auto;
    
    img:nth-child(1) {
      margin: 0 10px 0 0;
      width: $childrenWidth * $titleResize;
    }
    img:nth-child(2) {
      margin: 0 10px;
      top: 20px;
      width: $ofWidth * $titleResize;
    }
    img:nth-child(3) {
      margin: 0 5px 0 0;
      width: $valeWidth * $titleResize;
    }
  }

  #splash #novel-subline {
    width: $sublineWidth * $titleResize;
    margin: 0 auto;
  }

  #splash #novel-author {
    width: $authorWidth * $titleResize;
    margin: 0 auto;
  }
}

@media (max-width: 615px) {
  $titleResize: .5;

  #splash .novel_title {
    display: block;
  }

  #splash #novel-title-wrap {
    margin: 0 auto;
    text-align: center;

    img:nth-child(1) {
      margin: 0 auto;
      width: $childrenWidth * $titleResize;
    }
    img:nth-child(2) {
      margin: 0 auto;
      top: 0;
      
      width: $ofWidth * $titleResize;
    }
    img:nth-child(3) {
      margin: 0 auto;
      
      width: ($valeWidth * $titleResize) * 1.66;
    }
  }

  #splash #novel-subline {
    width: $sublineWidth * $titleResize;
    margin: 0 auto;
  }

  #splash #novel-author {
    width: $authorWidth * $titleResize;
    margin: 0 auto;
  }
}

@media (max-width: 410px) {
  $titleResize: .4;

  #splash .novel_title {
    display: block;
  }

  #splash #novel-title-wrap {
    margin: 0 auto;
    text-align: center;

    img:nth-child(1) {
      margin: 0 auto;
      width: $childrenWidth * $titleResize;
    }
    img:nth-child(2) {
      margin: 0 auto;
      top: 0;
      
      width: $ofWidth * $titleResize;
    }
    img:nth-child(3) {
      margin: 0 auto;
      
      width: ($valeWidth * $titleResize) * 1.66;
    }
  }

  #splash #novel-subline {
    width: $sublineWidth * $titleResize;
    margin: 0 auto;
  }

  #splash #novel-author {
    width: $authorWidth * $titleResize;
    margin: 0 auto;
  }

  #splash {
    .calls-to-action {
      margin-top: 20px;
      .btn {
        margin: 20px auto;
        display: block;
        width: 220px;
        text-align: center;
      }
    }
  }

}