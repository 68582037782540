/* ================= 12.0 Contact Section start ================= */

.form-group {
    margin-bottom: 20px;
}

.form-control {
    border: 0;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
    height: 44px;
    border: solid 1px #f1f1f1;
    color: #000;
    font-family: $sansSerif;
}

.form-control:focus {
    border: solid 1px #f1f1f1;
    box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.1) !important;
}

textarea.form-control {
    height: 120px;
    resize: none;
}

.form-control[readonly] {
    background: #fff;
}

.reservation-box .content-box {
    max-width: 600px;
}