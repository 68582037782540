.header-break {
  .content {
    text-align: center;
    padding: 25px 0;

    h1 {
      font-size: 48px;
      text-transform: uppercase;
      color: #2a2a2a;
      font-weight: 400;
      margin: 0;
    }
  }
  
  &.dark {
    background-color: #444;
    background-image: url(../images/patterns/grid_noise_dark_trans.png);
    background-repeat: repeat;
    background-position: center center;

    .content {
      h1 {
        color: #fff;
      }
    }
  }
}