/* Slider */
.slick-slider { position: relative; display: block; -moz-box-sizing: border-box; box-sizing: border-box; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -webkit-touch-callout: none; -khtml-user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }
.slick-list { position: relative; display: block; overflow: hidden; margin: 0; padding: 0; }
.slick-list:focus { outline: none; }
.slick-list.dragging { cursor: pointer; cursor: hand; }
.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }
.slick-track { position: relative; top: 0; left: 0; display: block; }
.slick-track:before, .slick-track:after { display: table; content: ''; }
.slick-track:after { clear: both; }
.slick-loading .slick-track { visibility: hidden; }
.slick-slide { display: none; float: left; height: 100%; min-height: 1px; }
[dir='rtl'] .slick-slide { float: right; }
.slick-slide img { display: block; }
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-initialized .slick-slide { display: block; }
.slick-loading .slick-slide { visibility: hidden; }
.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }
.slick-arrow.slick-hidden { display: none; }
 @charset 'UTF-8';
/* Slider */

/* Arrows */
.slick-prev, .slick-next { z-index:99; font-size: 0; line-height: 0; position: absolute; top: 50%; display: block; width: 30px; height: 50px; padding: 0; margin-top: -25px\9; /*lte IE 8*/ -webkit-transform: translate(0, -50%); -ms-transform: translate(0, -50%); transform: translate(0, -50%); cursor: pointer; color: transparent; border: none; outline: none; background: transparent; }
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { color: transparent; outline: none; background: transparent;}
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before { opacity: 1; }
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before { opacity: .25; }
.slick-prev:before, .slick-next:before { font-family: 'themify'; font-size: 30px; line-height: 1; opacity: 1; color: #fff; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
.slick-prev { left: 15px; }
[dir='rtl'] .slick-prev { right: 15px; left: auto; }
.slick-prev:before { content: "\e64a"; }
[dir='rtl'] .slick-prev:before { content: "\e64a"; }
.slick-next { right: 15px; }
[dir='rtl'] .slick-next { right: auto; left: 15px; }
.slick-next:before { content: "\e649"; }
[dir='rtl'] .slick-next:before { content: "\e649"; }
/* Dots */
.slick-slider { margin-bottom: 0; }
.slick-dots { position: absolute; bottom: -40px; display: block; width: 100%; padding: 0; list-style: none; text-align: center; }
.slick-dots li { position: relative; display: inline-block; width: 20px; height: 20px; margin: 0 0px; padding: 0; text-align:center; cursor: pointer; }
.slick-dots li button { font-size: 0; line-height: 0; display: inline-block; width: 8px; height: 8px; padding: 0; cursor: pointer; color: transparent; border: 0; outline: none; background: transparent; border:solid 2px #fff; border-radius:50%; }
.slick-dots li.slick-active button, .slick-dots li:hover button{background:#fff; border-color:#fff;}
.hero-slider {margin:0;}
.hero-slider .slick-dots {bottom: 20px;}