// MailingListSignup
@import '../variables.scss';

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.action-call-mailing-list-signup {
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
}

.mailing-list-signup {
  
  background: $brightGold;
  border-radius: 50px;
  font-weight: 500;
  font-size: 15px;
  box-shadow: none;
  border-color: $brightGold;
  color: #000;

  border-width: 2px;
  border-style: solid;
  display: inline-block;
  transition: all 0.3s ease-in;
  
  padding: 0 30px;


  form {
    display: inline-block;
    height: 54px;
    white-space: nowrap;

    button {
      background: $brightGold;
      display: inline-block;
      border: 0;
      width: 0;
      overflow: hidden;
      font-size: 28px;
      transition: all 0.3s ease-in;      
      margin: 0;

      position: relative;
      top: -1px;
      vertical-align: middle;
    }
  }

  .action-text {
    position: relative;
    top: 1px;

    font-size: 21px;
    font-family: $sansSerif, sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    padding: 15px 0;
    color: #000;
    transition: all 0.3s ease-in;

    display: inline-block;
    

    width: 202px;

    span {
      opacity: 1;
      transition: all 0.3s ease-in;
      display: inline-block;
      width: 202px;
    }

    &:hover {
      cursor: pointer;
      color: #fff;
    }
  }

  .ti {
    display: inline-block;
    width: 0;
  }

  &.inactive {
    &:hover {
      color: $brightGold;
    }
  }

  input[type="email"] {
    border: 0;
    display: inline-block;
    height: 100%;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-in;
    width: 0;

    font-family: $sansSerif;
    font-weight: 300;
    font-size: 16px;
    border-radius: 3px;
    vertical-align: top;
  }

  @media (max-width: 555px) {
    &.active {
      .action-text {
        
        width: 15px;

        position: relative;
        left: -15px;

        &:before {
          display: block;
          position: absolute;
          top: 25px;

          content: '';
          width: 25px;
          height: 2px;
          background: #000;
          transform: rotate(45deg);
        }

        &:after {
          display: block;
          position: absolute;
          top: 25px;

          content: '';
          width: 25px;
          height: 2px;
          background: #000;
          transform: rotate(-45deg);
        }
        
        span {
          overflow: hidden;
          opacity: 0;
          width: 0;
        }
      }

      button {
        vertical-align: top;
      }
    }
  }

  &.active {
    padding-right: 0;

    input[type="email"] {
      padding: 0 15px;  
      width: 220px;
      
      margin: 0 0 0 15px;
    }

    button {
      background: transparent;
      width: 54px;
      // margin-right: -25px;
      padding: 13px 10px;

      &:hover {
        color: #fff;
      }
    }

    .ti {
      width: 28px;
    }
  }

  .rotating {
    color: $vermillion;
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.message-wrap {
  margin-top: 15px;
  .message {
    font-family: sans-serif;
    font-size: 16px;
  }  
}

.signed-up {
  .mailing-list-signup {
    background: transparent;
    border-color: $brightGold;
    .action-text {
      color: $brightGold;
      &:hover {
        cursor: auto;
      }
    }
    .ti-check {
      margin-left: 5px;
    }
  } 
}