.btn {
  border-width: 2px;
  font-size: 21px;
  text-transform: uppercase;
  color: #000;
  font-weight: 300;


  &.btn-primary:hover,
  &.btn-primary:focus,
  &.btn-primary:active {
    background: transparent;
    color: #fff !important;
    border-color: #fff;
  }

  i {
    &.left {
      margin-right: 15px;
    }
    &.right {
      margin-left: 15px;
    }
  }

  &.color-purple {
    border-color: $lightPurple;
    color: $lightPurple;
    &.dark-text {
      color: #000;
      i { color: $lightPurple; }
      &:hover,
      &:focus,
      &:active {
        background-color: $lightPurple;
        color: #fff;
        i { color: #fff; }
      }
    }
  }
  &.color-cerulean {
    border-color: $cerulean;
    color: $cerulean;
    &.dark-text {
      color: #000;
      i { color: $cerulean; }
      &:hover,
      &:focus,
      &:active {
        background-color: $cerulean;
        color: #fff;
        i { color: #fff; }
      }
    }
  }
  &.color-brightgold {
    border-color: $brightGold;
    color: $brightGold;
    &.dark-text {
      color: #000;
      i { color: $brightGold; }
      &:hover,
      &:focus,
      &:active {
        background-color: $brightGold;
        color: #fff;
        i { color: #fff; }
      }
    }
  }
  &.color-vermillion {
    border-color: $vermillion;
    color: $vermillion;
    &.dark-text {
      color: #000;
      i { color: $vermillion; }
      &:hover,
      &:focus,
      &:active {
        background-color: $vermillion;
        color: #fff;
        i { color: #fff; }
      }
    }
  }

  &.solid {
    &.color-purple {
      border-color: $lightPurple;
      background: $lightPurple;
      color: #000;
    }
    &.color-cerulean {
      border-color: $cerulean;
      background: $cerulean;
      color: #000;
    }
    &.color-brightgold {
      border-color: $brightGold;
      background: $brightGold;
      color: #000;
    }
    &.color-vermillion {
      border-color: $vermillion;
      background: $vermillion;
      color: #000;
    }
  }
}

.calls-to-action {
  margin: 15px 0;
  text-align: center;
  
  .btn {
    margin: 12px;
  }
}