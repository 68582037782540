/* ================= 14.0 go top start ================= */

.go-top {
    position: fixed;
    border-radius: 50%;
    right: 25px;
    bottom: -15px;
    width: 40px;
    height: 40px;
    float: left;
    text-align: center;
    line-height: 40px;
    opacity: 0;
    visibility: hidden;
    font-size: 18px;
    background: #fff;
    line-height: 44px;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 99999;
}

.go-top.show {
    opacity: 1;
    visibility: visible;
    bottom: 25px;
}