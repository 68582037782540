/* ===================== 4.0 PRELOADER  start======================== */

.preloader {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    text-align: center
}

.spinner {
    position: relative;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    display: inline-block;
    background-color: #000;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out
}

.loaded .preloader {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: ease-out .2s;
    -moz-transition: ease-out .2s;
    -o-transition: ease-out .2s;
    transition: ease-out .2s
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0)
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}