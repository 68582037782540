/* ================= 13.0 Footer start ================= */

footer {
    background: #302c25;
    position: relative;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
}

footer h5 {
    color: #fff;
}

footer p {
    margin-bottom: 15px;
}

.footer-upper {
    padding: 60px 0 20px;
    box-shadow: inset 0 2px 32px rgba(0,0,0,0.9);
}

.footer-box {
    padding-right: 30px;
}

ul.contact-list {
    width: 100%;
    display: inline-block;
    vertical-align: top;
}

ul.contact-list li {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
    border-bottom: dashed 1px #333;
    padding: 7px 0 7px 25px;
    position: relative;
}

ul.contact-list li i {
    position: absolute;
    top: 13px;
    left: 0;
    color: #ebae5d;
}

ul.contact-list li a {
    color: #ebae5d;
}

ul.opening-hours {
    width: 100%;
    display: inline-block;
    vertical-align: top;
}

ul.opening-hours li {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
    border-bottom: dashed 1px #333;
    padding: 5px;
    position: relative;
}

ul.opening-hours li b {
    float: left;
    color: #ebae5d;
}

ul.opening-hours li span {
    float: right;
}

ul.footer-tweet-list li {
    margin-bottom: 15px;
}

ul.footer-tweet-list li a {
    color: #ebae5d;
}

ul.footer-tweet-list li span,
.info {
    display: block;
    font-size: 85%;
}

.newsletter-form {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    position: relative;
    max-width: 380px;
}

.newsletter-form button[type="submit"] {
    width: 40px;
    height: 42px;
    background: #ebae5d;
    position: absolute;
    top: 1px;
    right: 1px;
    border: 0;
    text-align: center;
    color: #fff;
    font-size: 18px;
    line-height: 42px;
    border-radius: 0 2px 2px 0;
}

footer ul.social-links {
    margin-left: -10px;
}

.footer-lower {
    padding: 30px 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    font-family: $altSansSerif;
}