.product-panel {
  .badge {
    font-size: 32px;
    border-radius: 28px;
    padding: 10px;
    margin-right: 10px;
    width: 52px;
    &.color-brightgold {
      i { color: #000; }
    }
  }

  h3 {
    font-size: 64px;
  }  

  h3, h4, h5, p {
    color: #000;
  }

  h5 {
    font-weight: 200;
  }

  h6 {

  }

  .list-group-item {
    ul {
      list-style: disc;
      width: 75%;
      margin: 0 auto 15px;
      color: #aaa;
    }
  }

  p {
    font-family: $altSansSerif;
    &.list-group-item-text {
      text-indent: 40px;
    }
  }
}