/* ================= 10.0 Gallery Section start ================= */

.gallery-container {
    width: 100%;
    display: inline-block;
    vertical-align: top;
}

.grid-sizer,
.grid-item {
    width: 16.66%;
}

.gutter-sizer {
    width: 1%;
}

.grid-item {
    float: left;
    padding: 10px;
}

.gallery .grid-item {
    padding: 3px !important;
}

.box-item a {
    float: left;
    position: relative;
}

.box-item a:hover:before {
    opacity: 1;
}

.box-item {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.box-item a:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9;
    opacity: 0;
    transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
}

.grid-item:hover a:before {
    opacity: 1;
}

.box-item a:after {
    content: "\e61a";
    font-family: 'themify';
    position: absolute;
    top: 50%;
    opacity: 0;
    z-index: 10;
    left: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: -15px 0 0 -15px;
    color: #fff;
    font-size: 32px;
    -webkit-transform: scale(0.4);
    -moz-transform: scale(0.4);
    -o-transform: scale(0.4);
    -ms-transform: scale(0.4);
    transform: scale(0.4);
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    transition: all 200ms linear;
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.grid-item:hover a:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

.grid-item img {
    width: 100%;
    position: relative;
    z-index: 1;
    -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -moz-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
}

.grid-item:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}