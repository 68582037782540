/* ===================== 5.0 Search  start ======================== */

.search-box-outer {
    position: fixed;
    width: 100%;
    height: 60px;
    float: left;
    background: #fff;
    z-index: 99999999;
    left: 0;
    transition: all 0.2s ease-in;
    top: -60px;
}

.search-box {
    width: 100%;
    position: relative;
    padding-right: 60px;
}

.search-box .form-control,
.search-box .form-control:focus {
    height: 60px;
    border: 0;
    box-shadow: none !important;
    font-size: 24px;
}

.close-search {
    width: 60px;
    height: 60px;
    float: left;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
}

.show-search .search-box-outer {
    top: 0;
}

.search-box-outer .container {
    padding: 0;
}