/* ================= 7.0 Hero Section start ================= */

.hero-container {
    position: relative;
    height: 100%;
    color: #fff;
}

.hero-slide {
    width: 100%;
    height: 100%;
    display: table;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
}

.slick-list,
.slick-track {
    height: 100% !important;
}

.hero-slide-content {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 99;
    background: rgba(0, 0, 0, 0.6);
}

.hero-slide-content h6 {
    color: #fff;
    font-size: 18px;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 5px;
    font-weight: 400;
    letter-spacing: 6px;
    color: $lightPurple;
}

.hero-slide-content h3 {
    font-size: 80px;
    line-height: 1.2;
    margin-bottom: 15px;
}

.hero-slide-content h3 span {
    color: $lightPurple;
}

.hero-slide-content p {
    font-size: 16px;
    line-height: 1.7;
}

.slide-content {
    margin-top: 50px;
    padding: 0 30px;
}

.slick-dots li.slick-active button,
.slick-dots li:hover button {
    background: $lightPurple;
    border-color: $lightPurple;
}