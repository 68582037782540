#review-header {
  margin-top: 60px;
  margin-bottom: 0;

  .motif_1:not(.flip-v) {
    margin-top: -30px;
  }
}

.review-prompt {
  padding-top: 25px;
}

#prrequest-check {
  text-align: center;
}

#reviews-wrap {
  #review-carousel {
    padding-bottom: 25px;
  }
}

#reviews {
  position: relative;

  .form-control {
    font-size: 18px;
  }

  #add-review-form {
    // padding-top: 100px;
    .form-group.form-check {
        i {
          color: $cerulean;
          font-size: 30px;
          padding: 10px;
          cursor: pointer;
        }
        label {
          font-size: 18px;
        }
      }

    .controls {
      button {
        appearance: none;
        float: right;
        transition: all 0.3s ease;
        color: $vermillion;
        padding: 15px;
        margin-top: 8px;
        &:hover {
          color: lighten($vermillion, 15%);
        }
        i {
          font-size: 36px;
        }
      }
    }
    .form-row {
      margin-bottom: 10px;
    }
    .page-header {
      margin-top: 0;
    }
    label {
      font-weight: normal;
      font-family: $altSansSerif;
    }
    input, select, textarea {
      font-weight: normal;
      font-family: $altSansSerif;
    }
    #rating-stars {
      h4 {
        display: inline-block;
        margin-right: 15px;
      }
      ul {
        display: inline-block;
        padding-left: 10px;
        &:hover {
          cursor: pointer;
        }
        li {
          display: inline-block;
          i {
            padding: 5px 2px 5px 0;
            font-size: 36px;
          }
          &:last-child {
            i {
              padding-right:  10px;
            }
          }
        }
      }
      #rating-stars-text {
        margin-left: 5px;
        font-size: 28px;
        vertical-align: middle;
        font-style: italic;
        color: #aaa;
      }
      [class*='rating-'] {
        span {
          &:first-child {
            font-weight: 500;
          }
          &:last-child {
            font-weight: 500;
            color: $brightGold;
          }
        }
      }
      .rating-1 {
        .number {
          color: mix(#aaa, $brightGold, 100%);
        }
      }
      .rating-2 {
        .number {
          color: mix(#aaa, $brightGold, 75%);
        }
      }
      .rating-3 {
        .number {
          color: mix(#aaa, $brightGold, 50%);
        }
      }
      .rating-4 {
        .number {
          color: mix(#aaa, $brightGold, 25%);
        }
      }
      .rating-5 {
        .number {
          color: mix(#aaa, $brightGold, 0%);
        }
      }

      
    }
  }

  button {
    .fa {
      font-size: 24px;
      &.fa-arrow-right {
        color: $brightGold;
        transition: all 0.3s ease-in-out;
      }
    }
    &:hover {
      .fa-arrow-right {
        color: #fff;
      }
    } 
  }

  .call-to-action {
    a {
      transition: all 0.3s ease-in-out;
      
      &:hover {
        color: #fff;
      }
      
    }
  }

  .required-warn {
    border: 2px solid $vermillion;
    /* text-align: center; */
    border-radius: 7px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
  }
}


#reviews-submission-wrap {
  h1, h2, h3, h4 {
    text-transform: initial;
  }
}



@media (max-width: 500px) {
  #reviews #modal #add-review-form #rating-stars ul li i,
  #rating-stars-text span {
    font-size: 24px;
  }
}