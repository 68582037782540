/* ================= 9.0 Service Section start ================= */

ul.service-list {
    height: auto;
    float: left;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
}

ul.service-list li {
    width: 33.3%;
    height: auto;
    float: left;
    padding: 15px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.service-box {
    background: #fff;
    padding: 50px 40px 90px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d7cdbc;
    position: relative;
    text-align: center;
    transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
}

.service-book,
.service-book:focus {
    width: 100%;
    text-align: center;
    background: #302c25;
    color: #ebae5d;
    font-weight: 400;
    font-family: $sansSerif;
    font-size: 24px;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #d7cdbc;
    position: relative;
    min-height: 200px;
}

.service-book:hover {
    color: #333;
    background: #fff;
}

.service-book b {
    font-size: 120px;
    display: block;
    line-height: 1;
}

.service-book span {
    top: 50%;
    display: inline-block;
    transform: translateY(-50%);
    position: absolute;
    text-align: center;
    left: 0;
    width: 100%;
}

.service-box:hover {
    background: #302c25;
    color: #ebae5d;
}

.service-icon {
    width: 80px;
    height: 80px;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    margin-bottom: 20px;
    font-size: 80px;
}

.service-box h4 {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 15px;
    margin: 0;
}