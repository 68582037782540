/* =================3.0 Buttons start here================= */

.btn {
    border-radius: 50px;
    font-weight: 400;
    padding: 12px 30px;
    font-size: 15px;
    box-shadow: none;
    font-family: $sansSerif;
}

.btn-default {
    color: #fff;
    background-color: $purple;
    border: solid 1px $purple;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
    color: $purple;
    background-color: transparent;
    border-color: $purple;
}

.btn-primary {
    color: $purple;
    background-color: transparent;
    border: solid 1px $purple;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    color: #fff;
    background-color: $purple;
    border-color: $purple;
}

.btn-second {
    color: #fff;
    background-color: $purple;
    border: solid 1px $purple;
}

.btn-second:hover,
.btn-second:focus,
.btn-second:active {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

.btn.btn-lg {
    padding: 18px 40px !important;
}

.btn+.btn {
    margin-left: 10px;
}