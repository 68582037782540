/* ================= 11.0 Blog Section start ================= */

ul.recent-blog-list {
    margin: 0 -15px
}

ul.recent-blog-list li {
    width: 33.3%;
    float: left;
    padding: 15px;
}

.blog-post-box {
    padding: 0;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #d7cdbc;
    position: relative;
}

.blog-post-img {
    position: relative;
}

.blog-post-img img {
    width: 100%;
}

.post-date {
    background: #fff;
    color: #2a1f1d;
    display: inline-block;
    // position: absolute;
    // left: 5px;
    // top: 5px;
    padding: 10px;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    font-weight: 400;
}

.post-date span {
    display: block;
    font-size: 16px;
    font-weight: 700;
}

.meta {
    font-size: 11px;
    display: inline-block;
    border-radius: 3px;
    margin-bottom: 5px;
}

.meta span {
    display: inline-block;
    vertical-align: top;
}

.meta span i {
    vertical-align: middle;
    margin-right: 5px;
}

.meta span+span {
    margin-left: 15px;
}

.blog-content {
    padding: 25px;
}