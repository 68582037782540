#cov-global-header {
  background: rgba(0,0,0,0.85);

  .logo {
    width: auto;
    padding: 7px;

    img {
      height: 100%;
    }

    #author {
      padding: 8px 0 8px 25px;
      margin-top: 5px;
    }

    & > a {
      display: inline-block;
    }
    
  }

  #navbar {
    overflow: scroll;
    li {
      text-align: center;
      a {
        font-family: $sansSerif, sans-serif;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: $defaultMedium;

        &:link,
        &:visited {
          color: #fff;
        }
      }

      .btn {
        &.color-purple {
          border-color: $lightPurple;
          color: $lightPurple;
        }

        &.color-cerulean {
          border-color: $cerulean;
          color: $cerulean;
        }

        &.color-brightgold {
          border-color: $brightGold;
          color: $brightGold;
        }

        &.color-vermillion {
          border-color: $vermillion;
          color: $vermillion;
        }

        &:hover {
          cursor: pointer;
          color: #fff;
        }
      }
    }

  }
}

@media (max-width: 1142px) {
  #cov-global-header #navbar li .btn {
    &.color-purple,
    &.color-cerulean,
    &.color-brightgold {
      display: inline-block !important; 
    }
    
  }
}