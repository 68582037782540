// COLORS
$gray: #ccc;

$purple: #7747ba;
$lightPurple: #ad83e7;
$lavender: #d3bdf3;

$lightYellow: #e7ca83;
$yellow: #ba9e47;
$gold: #ac8e24;
$brightGold: #f1c22f;

$vermillion: #c6402f;
$cerulean: #43a6ec;

// TYPOGRAPHY
$sansSerif: 'Oswald';
$altSansSerif: 'Open Sans';
$defaultMedium: 400;
