/* ================= 8.0 About Section start ================= */

.section {
    border-bottom: 1px solid #d7cdbc;
    padding: 100px 0;
}

.heading-container {
    text-align: center;
    margin-bottom: 70px;
}

.heading-container h6 {
    font-family: $sansSerif;
    font-size: 44px;
    font-weight: 400;
    position: relative;
    margin-bottom: 10px;
}

.heading-container h3 {
    margin: 0;
}

.heading-container p {
    max-width: 480px;
    margin: 0 auto;
    font-size: 15px;
}

.heading-icon {
    width: 80px;
    height: 80px;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    margin-bottom: 20px;
    font-size: 80px;
    color: rgba(0, 0, 0, 0.2);
}

.about-img {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    box-shadow: 15px 15px 0px rgba(0, 0, 0, 0.1);
}

.partition {
    display: inline-block;
    width: 100%;
    margin: 10px 0;
    height: 1px;
    vertical-align: top;
}

.partition:before {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 40px;
    background: #d1d1d1;
    height: 1px;
}

.content-container {}

blockquote {
    font-weight: 400;
    font-family: $sansSerif;
    font-size: 24px;
    line-height: 1.5;
    border: 0;
    padding: 0;
    color: #666;
}

.appointent-form-box {
    padding: 40px;
    position: relative;
    background: #fff;
    border: 1px solid #d7cdbc;
    border-radius: 5px;
}

.appointent-content-box {
    padding: 40px 20%;
    position: relative;
    text-align: center;
}

ul.stats-list {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-top: 50px;
    border-top: solid 1px #f1f1f1;
    padding-top: 20px;
    text-align: center;
}

ul.stats-list li {
    width: 33.3%;
    float: left;
    padding: 20px;
    font-family: $sansSerif;
}

ul.stats-list li h3 {
    color: #ebae5d;
    margin-bottom: 10px;
}

ul.stats-list li p {
    font-size: 24px;
}