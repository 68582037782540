/* ================= 6.0 Header start ================= */

header {
    background: transparent;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0;
    top: 0;
    color: inherit;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 9999;
    font-weight: nrmal;
    font-family: $sansSerif;
    font-size: 15px;
    color: #fff;
    transition: all 0.1s ease-in;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}

header.fixed {
    -webkit-box-shadow: 0 4px 10px -8px rgba(0, 0, 0, 0.45);
    box-shadow: 0 4px 10px -8px rgba(0, 0, 0, 0.45);
    border: 0;
    color: #000;
}

header:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 0;
    width: 100%;
    background: #fff;
    transition: all 0.2s ease-in;
}

.header-bar {
    display: block;
    margin: 0 -15px;
    position: relative;
    z-index: 999;
}

header.fixed:after {
    height: 100%;
}

header .logo-dark {
    display: none;
}

header .logo-light {
    display: block;
}

header.fixed .logo-dark {
    display: block;
}

header.fixed .logo-light {
    display: none;
}

.logo {
    float: left;
    width: 120px;
    padding: 15px;
    height: 60px;
    position: relative;
    z-index: 20;
}

.logo a {
    float: left;
}

.logo small {
    position: absolute;
    left: 100%;
    top: 50%;
    text-align: center;
    margin-top: -8px;
    border-left: solid 1px #fff;
    font-size: 15px;
    line-height: 10px;
    padding: 5px 10px;
    opacity: 0.5;
}

.navbar-default .navbar-nav>li>a {
    color: inherit;
    padding: 10px 15px;
}

.navbar-default .navbar-nav>li>a.hero-btn {
    background: #ebae5d !important;
    border-radius: 40px;
    color: #000 !important;
    padding: 10px 20px;
}

.navbar-default .navbar-nav>li>a.hero-btn:hover,
.navbar-default .navbar-nav>li>a.hero-btn:focus {
    color: #000 !important;
}

.navbar-default .navbar-nav>li>a:hover {
    color: #ebae5d;
}

.navbar-default .navbar-nav>li>a:focus {
    color: inherit;
}

.navbar-right {
    margin: 0;
}

.navbar-default {
    background: transparent;
    border: 0;
    margin: 0;
    box-shadow: none;
    z-index: 10;
}

.navbar-nav>li {
    padding: 10px 5px;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #ebae5d;
    background: transparent;
}

.navbar-toggle {
    padding: 0;
    border: 0;
    background: transparent;
    right: 0;
    left: auto;
    margin-top: 18px;
    margin-left: 12px;
    position: absolute;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background: transparent;
}

.navbar-toggle {
    width: 30px;
    height: 24px;
    cursor: pointer;
    text-decoration: none;
}

.navbar-toggle .icon-bar {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 1px;
    color: #243238;
    text-indent: -55px;
    background: transparent!important;
    transition: all .2s ease-out;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    vertical-align: middle;
}

.navbar-toggle .icon-bar:before,
.navbar-toggle .icon-bar:after {
    content: '';
    width: 28px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    transition: all .2s ease-out;
}

.navbar-toggle.collapsed .icon-bar {
    background: #fff!important;
}

.navbar-toggle.collapsed .icon-bar:before,
.navbar-toggle.collapsed .icon-bar:after {
    background: #fff;
}

.fixed .navbar-toggle.collapsed .icon-bar {
    background: #000 !important;
}

.fixed .navbar-toggle.collapsed .icon-bar:before,
.fixed .navbar-toggle.collapsed .icon-bar:after,
.fixed .navbar-toggle .icon-bar:before,
.fixed .navbar-toggle .icon-bar:after {
    background: #000;
}

.navbar-toggle.collapsed .icon-bar:before {
    top: -8px;
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
}

.navbar-toggle .icon-bar:before {
    top: 0;
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.navbar-toggle.collapsed .icon-bar:after {
    bottom: -8px;
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
}

.navbar-toggle .icon-bar:after {
    bottom: 0;
    -webkit-transform: rotateZ(-45deg);
    -moz-transform: rotateZ(-45deg);
    -ms-transform: rotateZ(-45deg);
    -o-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
}

.header-right {
    float: right;
    z-index: 20;
    position: relative;
    margin: 0 15px;
}

.search-icon {
    float: left;
    height: 60px;
    border-left: solid 1px rgba(255, 255, 255, 0.2);
    transition: border-color .5s ease-out;
}

.search-icon a {
    float: left;
    line-height: 20px;
    padding: 20px;
    font-size: 20px;
}

ul.social-links {
    display: inline-block;
    vertical-align: top;
}

header ul.social-links {
    border-left: solid 1px rgba(255, 255, 255, 0.2);
    border-right: solid 1px rgba(255, 255, 255, 0.2);
    padding: 10px;
    transition: border-color .5s ease-out;
}

ul.social-links li {
    float: left;
}

ul.social-links li a {
    float: left;
    line-height: 20px;
    padding: 10px;
    font-size: 13px;
}

header.fixed ul.social-links,
header.fixed .search-icon {
    border-color: #f1f1f1;
}

header.fixed .logo small {
    border-color: rgba(0, 0, 0, 0.2)
}