.review-blurb {
  position: relative;
  padding-bottom: 15px;

  span.quotations {
    display: block;
    height: 78px;
    width: 107px;
    opacity: 0.15;
    position: absolute;

    &.left {
      background-image: url('/images/quotations_left.png');
      left: 0;
      top: 35px;
    }

    &.right {
      background-image: url('/images/quotations_right.png');
      right: 0;
      bottom: 35px;
    }
  }

  .reviewer {
    font-size: 21px;
    .credit {
      font-style: italic;
      color: #aaa !important;
    }
  }

  .link {
    font-size: 16px !important;
    color: #aaa !important;
    a {
      text-transform: capitalize;
      font-weight: 500;
    }
    i {
      font-size: 21px !important;
      vertical-align: text-bottom;
      margin-left: 5px;
    }
  }

}

#review-carousel {
  // full-size image background
  // background-color: #ccc;
  // background-image: url('/images/carousel_review.jpg');
  // background-repeat: no-repeat;

  background-color: #191A1C;
  background-image: url('/images/carousel_review_3.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  h6, p {
    color: #fff;
  }
}

