#novel-carousel,
#novel-intro {
  background-color: #fff;
  .hero-slide {
    background-attachment: scroll;
    background-position-x: 0;
    background-size: auto;
  }
  .hero-slide-content {
    background: none;
  }

  #novel-intro-content {
    span:first-of-type {
      margin-bottom: 15px;
    }
    p {
      color: #000;
      font-family: 'Taviraj';
      font-size: 18px;
      text-indent: 40px;
    }

    .calls-to-action {
      margin-bottom: 0;
    }
  }


  // DARK UI FIXES
  .slick-next,
  .slick-prev {
    &:before {
      color: #000;
    }
  }
  .slick-dots {
    button {
      border-color: #000;
    }
    .slick-active {
      button {
        background-color: #000;
      }
    }
  }

  // REVIEW COMPONENT FIXES
      
  .review-blurb {
    margin-top: 30px;
    h3 {
      font-size: 40px;
      letter-spacing: 1px;
    }
    i {
      font-size: 40px;
      letter-spacing: 1px;
    }

    h6.text {
      letter-spacing: 1px;
      color: #000;
      font-size: 24px;
      font-weight: 300;
      font-family: $sansSerif;
    }
    h6.reviewer {
      letter-spacing: 1px;
    }
    h6.link {
      letter-spacing: 1px;
    }
    span.quotations {
      &.left {
        background-image: url('/images/quotations_left_dark.png');
        top: 0;
      }
      &.right {
        background-image: url('/images/quotations_right_dark.png');
        top: 30%;
      }
    }
  }
}

@media (max-width: 450px) {
  #novel-intro-content {
    max-width: 100%;

    btn {
      width: 160px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
    }
  }
}


@media (max-width: 1320px) {

  #novel-carousel,
  #novel-intro {
    min-height: 930px;
  }
}

@media (max-width: 991px) {
  #novel-carousel,
  #novel-intro {

    &.hero-container {
      min-height: 1600px;
    }

    .hero-slide {
      .calls-to-action {
        btn {
          margin: 10px;
        }  
      }
    }

    #novel-intro-content {
      img {
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 680px) {
  #novel-carousel,
  #novel-intro {
    min-height: 1300px;
  }
}

