// CUSTOM VARS
@import './variables.scss';

@import './bootstrap-3.scss';

// MAU THEME
@import './mau/reset.scss';
@import './mau/core.scss';
@import './mau/buttons.scss';
@import './mau/preloader.scss';
@import './mau/search.scss';
@import './mau/header.scss';
@import './mau/hero.scss';
@import './mau/about.scss';
@import './mau/services.scss';
@import './mau/gallery.scss';
@import './mau/blog.scss';
@import './mau/contact.scss';
@import './mau/footer.scss';
@import './mau/goToTop.scss';
@import './mau/themify-icons.scss';

// THIRD PARTY STYLESHEETS
@import './slick.scss';

// THEME CUSTOMIZATIONS
// GLOBAL COMPONENTS
@import './components/header.scss';
@import './components/splash.scss';
@import './components/buttons.scss';
@import './components/product-panel.scss';
@import './components/releaseCallout.scss';

// STATIC CONTENT COMPONENTS
@import './components/novelCarousel.scss';
@import './components/reviewCarousel.scss';
@import './components/headerBreak.scss';

// PAGES
@import './pages/contact.scss';
@import './pages/blog.scss';
@import './pages/reviews.scss';


// GLOBAL SETTINGS
body {
  background: #f5f5f5 url(../images/patterns/dust.png) repeat center center;
}

footer {
  background-color: #333;
  background-image: url(../images/patterns/white_leather_trans.png), linear-gradient(45deg, black, transparent), linear-gradient(180deg, black 5px, transparent);
  background-repeat: repeat;
  background-position: center center;
}
section.section {
  &.texture-paper {
    background-color: #fff;
    background-image: url(../images/patterns/exclusive_paper.png);
    background-repeat: repeat;
  }

  h1, h2, h3, h4 {
    font-weight: 400;
    text-transform: uppercase;
  }
  p {
    font-family: $altSansSerif;
  }
}

#faq {
  .list-group-item {
    padding: 25px;
  }
}

// GLOBAL UTILS
.seo, .hidden {
  display: none;
}

// DECORATIONS
.motif_1 {
  background: url('/images/motif_4.png') no-repeat center center;
  min-height: 76px;
  max-width: 200px;
  display: block;
  margin: 0 auto;
  position: relative;
  top: 10px;

  &.flip-v {
    transform: rotate(180deg);
    top: 10px;
  }
}

.motif_5 {
  position: relative;
  &:before, &:after {
    opacity: 0.35;
    content: '';
    background: url(/images/motif_5.png) no-repeat center center;
    display: inline-block;
    position: absolute;
    height: 150px;
    top: 0;
    min-width: 54px;
    z-index: 1;
  }
  &:before {
    left: -100px;
    top: 2px;
  }
  &:after {
    right: -100px;
    transform: rotate(180deg);
  }
}

// CAROUSELS
.auto-slider {
  .slick-slide,
  .slick-track,
  .slick-list {
    height: auto !important;
    min-height: 200px;
  }
  .slide-content {
    margin-bottom: 50px;
  }
  .slick-dots {
    bottom: 15px;
  }
}

// COLORS
// TEXT
.emphasize {
  font-weight: 700;
  font-style: italic;
}

.color-purple {
  color: $lightPurple;
}
.color-cerulean {
  color: $cerulean;
}
.color-brightgold {
  color: $brightGold;
}
.color-vermillion {
  color: $vermillion;
}

a.color-purple {
  color: $lightPurple;
  &:hover {
    color: lighten($lightPurple, 15%);
  }
}
a.color-cerulean {
  color: $cerulean;
  &:hover {
    color: lighten($cerulean, 15%);
  }
}
a.color-brightgold {
  color: $brightGold;
  &:hover {
    color: lighten($brightGold, 15%);
  }
}
a.color-gold {
  color: $gold;
  i {
    color: $brightGold;
  }
  &:hover {
    color: lighten($gold, 15%);
  }
}
a.color-vermillion {
  color: $vermillion;
  &:hover {
    color: lighten($vermillion, 15%);
  }
}

// BADGES COLORS
.badge.color-purple {
  background-color: $lightPurple;
  i {
    color: #fff;
  }
}
.badge.color-cerulean {
  background-color: $cerulean;
  i {
    color: #fff;
  }
}
.badge.color-brightgold {
  background-color: $brightGold;
  i {
    color: #fff;
  }
}
.badge.color-vermillion {
  background-color: $vermillion;
  i {
    color: #fff;
  }
}


// SPLASH FIXES


#title_vale {
  text-align: center;
  img {
    height: 50%;
  }
}

// important
// .hero-slide.parallax {
//   position: relative;
// }

#novel-title {
  width: 100%;
  height: 100%;
}


// LAST MINUTE RESPONSIVE FIXES
@media (max-width: 555px) {
  .logo {
    #author {
      display: none !important;
    }
  }
}

// READER
@import './reader.scss';



// SOCIAL MEDIA ICONS
#social-media-icons {
  .social-headline {
    margin-bottom: 55px;
    text-align: center;
    h4 {
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 5px;
      font-weight: 200;
      em {
        color: $gray;
        font-style: normal;
        font-weight: 400;
      }
    }
    span {
      font-family: $altSansSerif;
      font-style: italic;
      letter-spacing: 1px;
    }
  }
  
  .social-icon {
    text-align: center;
    margin-bottom: 50px;
    font-size: 22px;

    a, svg {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }

    a:hover path {
      fill: $lightPurple;
    }

    a:hover {
      color: $lightPurple;
      transition: all 0.5s ease-in-out;
      .fa {
        color: $lightPurple;
        transition: all 0.5s ease-in-out;
      }
    }

    a path {
      // fill: rgba(255, 255, 255, 0.6);
      fill: #fff;
      transition: all 0.5s ease-in-out;
    }

    & > a span:first-child {
      font-size: 36px;
    }
    & > a svg:first-child {
      height: 36px;
      min-width: 63px;
    }

    .social-icon-text {
      margin-left: 10px;
    }
  }
}