/* ================= 2.0 Core css starts here================= */

html,
body {
    height: 100%;
}

body {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-family: $sansSerif;
    color: #000;
    background: #f5f5f5 url(../images/bg.jpg) repeat center center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    width: 100%;
    max-width: 1300px;
}

a,
input,
button {
    text-decoration: none;
    color: inherit;
    outline: none;
    transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
}

a img {
    border: 0px none;
}

a:hover {
    outline: none;
    color: $lightPurple;
    text-decoration: none;
}

a:active {
    outline: none;
    text-decoration: none;
}

a:focus {
    outline: none;
    outline-offset: 0px;
    text-decoration: none;
    color: inherit;
}

::-webkit-input-placeholder {
    color: #999;
    opacity: 1;
}

::-moz-placeholder {
    color: #999;
    opacity: 1;
}

:-moz-placeholder {
    color: #999;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #999;
    opacity: 1;
}

img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    padding: 0px;
    margin-bottom: 15px;
    line-height: 1.2;
    font-weight: 300;
    font-family: $sansSerif;
    color: inherit;
}

h1 {
    font-size: 60px;
}

h2 {
    font-size: 48px;
}

h3 {
    font-size: 40px;
}

h4 {
    font-size: 32px;
}

h5 {
    font-size: 26px;
}

h6 {
    font-size: 22px;
}

p {
    margin: 0px;
    padding: 0px;
    margin-bottom: 25px;
}

b {
    font-weight: 600;
}

strong {
    font-weight: 700;
}

p:last-child {
    margin-bottom: 0;
}

.large-para {
    font-size: 18px;
    line-height: 27px;
    color: #000;
}

.mar-40 {
    margin-bottom: 40px !important;
}

.mar-30 {
    margin-bottom: 30px !important;
}

.no-mar {
    margin: 0 !important;
}

.no-pad {
    padding: 0 !important;
}

.os-animation {
    opacity: 0;
}

.os-animation.animated {
    opacity: 1;
}

iframe {
    border: 0;
    width: 100%;
    height: auto;
}