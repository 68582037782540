// RELEASE CALLOUT
#release-callout {
  background: #000;
  color: #f5f5f5;
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
    background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    content: '';
    z-index: 2;
  }

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    z-index: 1
  }

  #release-splash-wrapper {
    text-align: center;
  }

  #release-splash {
    text-align: center;
    position: relative;

    min-height: 130px;
    display: inline-block;
    width: auto;
    z-index: 3;

    margin: 0 auto;

    &:before, &:after {
      opacity: 0.35;
      content: '';
      background: url(/images/motif_5.png) no-repeat center center;
      display: inline-block;
      position: absolute;
      height: 150px;
      top: 0;
      min-width: 54px;
      z-index: 1;
    }
    &:before {
      left: -100px;
    }
    &:after {
      right: -100px;
      transform: rotate(180deg);
    }

    h1 {
      font-family: 'Roboto Condensed', sans-serif;
      text-transform: uppercase;
      font-weight: 600;
    }

    p {
      font-family: 'Roboto Condensed', sans-serif;
      font-size:  24px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

  }

  .motif_1 {
    top: 0;
  }

  .action-call-kickstarter {
    margin: 0;
  }
}