@import '../variables.scss';

// COMPONENTS
@import '../components/mailingListSignup.scss';

#contact {
  .hero-slide {
    .hero-slide-content {
      background: none;

      h3, p {
        color: #000;
      }
    }
  }

  #mailing-list-callout {
    .heading-container {
      margin-top: 125px;

      h1 {
        font-size: 48px;
        font-family: $sansSerif, sans-serif;
        text-transform: uppercase;
        
      }

      h3 {
        font-size: 28px;
        font-family: $sansSerif, sans-serif;
        text-transform: uppercase;
        
      }

      p {
        margin-top: 15px;
        font-family: $altSansSerif, sans-serif;
      }
    }
  }

  .action-call-mailing-list-signup {
    margin-top: 20px;
  }
}

#mail-author {
  font-family: $altSansSerif;
}