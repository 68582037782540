#blog {
  #blog-header {
    margin-top: 60px;

    .motif_1:not(.flip-v) {
      margin-top: -30px;
    }
  }

  #blog-archive {
    margin-bottom: 45px;
    h2 {
      font-family: $altSansSerif;
      font-weight: 600;
      font-size: 24px;
      text-transform: uppercase;
    }

    li {
      h3 {
        font-size: 21px;
        font-weight: 500;
        margin-bottom: 0;
        a {
          &:hover {
            color: $cerulean;
          }
        }
      }
      date {
        font-family: $altSansSerif;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        color: #5f5f5f;

      }
    }
  }

  #blog-content {
    h2 {
      font-family: $sansSerif;
      font-weight: 600;
      font-size: 24px;
      text-transform: uppercase;
    }
  }

  .post-content {
    margin-bottom: 75px;
    .post-title {
      font-weight: 400;

      a {

        &:hover {
          color: $cerulean;
        }
      }
    }

    .post-date {
      font-family: $altSansSerif;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      color: #5f5f5f;
      margin: -10px 0 35px 0;
      // display: block;
    }

    p {
      text-indent: 20px;
      font-family: $altSansSerif;
      font-size: 18px;
      // font-weight: 500;
    }

    a {
      &:link {
        color: $purple;
      }
      &:hover {
        color: lighten($purple, 20%);
      }
    }
  }
}
